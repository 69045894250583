<template>
    <zw-sidebar @shown="shown" :title="$t('multichannel.title.update_price')">
        <div class="px-3 py-2">
            <validation-observer tag="div" ref="observer">
                <b-overlay :show="loading" no-wrap></b-overlay>
                <div v-if="!loading">
                    <b-row>
                        <b-col cols="12">
                            <b-alert variant="info" show v-if="form.source == 'selected'">
                                <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                                {{ $t('article.text.selected_count', {'count': payload.selectedData.ids.length}) }}
                            </b-alert>
                            <b-alert variant="info" show v-else>
                                <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                                {{ $t('export.source.' + form.source) }}
                            </b-alert>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.type"
                                             :options="typesOptions"
                                             name="type"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-select-group v-model="form.direction"
                                             :options="directionOptions"
                                             name="direction"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-input-group v-model="form.amount"
                                            name="amount"
                                            type="number"
                                            :label-prefix="labelPrefix"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </validation-observer>
        </div>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'UpdateStoreArticlePrice',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            typesOptions: [
                {value: 'fixed', text: this.$t('multichannel.label.update_price.types.fixed')},
                {value: 'percent', text: this.$t('multichannel.label.update_price.types.percent')},
            ],
            directionOptions: [
                {value: 'up', text: this.$t('multichannel.label.update_price.directions.up')},
                {value: 'down', text: this.$t('multichannel.label.update_price.directions.down')},
            ],
            defaultForm: {
                type: 'fixed',
                direction: 'up',
                source: 'selected',
                amount: 0,
                ids: [],
                id: 1, // for update message
            },
            form: {},
            labelPrefix: 'multichannel.label.update_price.',
        }
    },
    methods: {
        shown() {
            this.form = JSON.parse(JSON.stringify(this.defaultForm))
            this.form.ids = this.payload.selectedData.ids
            this.form.source = this.payload.selectedData.selectedSource
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let filter = this.payload.selectedData.filter;
                    filter['api_source_id'] = this.payload.sourceId
                    filter['api_store_id'] = this.payload.storeId

                    let sendData = {
                        ...this.payload.selectedData,
                        filter: filter,
                        form: this.form,
                    }

                    this.$store.dispatch('Multichannel/updatePrice', sendData)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>